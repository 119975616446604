import React from 'react'
import SEO from "../../../components/atoms/seo"

import Layout from '../../../components/organisms/layout'
import Heading from '../../../components/molecules/heading'
import Title from '../../../components/atoms/title'
import Link from '../../../components/atoms/link'

const StyleguideComponentsPage = () => (
  <Layout>
    <SEO title='Styleguide' />
    <Heading 
        title='Styleguide - Components'
        weight='light'
    />
    <div className='p-styleguide'>
      <Link url='./chat-bot' classNames='p-styleguide__link'>
        <Title
          type='h3'
          title='Chat Bot'
          align='center'
          color='dark'
          weight='bold'
        />
      </Link>
      <Link url='./loader' classNames='p-styleguide__link'>
        <Title
          type='h3'
          title='Loader'
          align='center'
          color='dark'
          weight='bold'
        />
      </Link>
    </div>
  </Layout>
)

export default StyleguideComponentsPage
